var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('user.pageTitle'),"breadcrumb":[
                { text: _vm.$t('home'), href: '/' },
                {
                    text: _vm.$t('user.pageTitle'),
                    active: true
                }
            ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'user-create' })}}},[_vm._v(" "+_vm._s(_vm.$t('addNew'))+" ")]),_c('div',{staticClass:"tw-space-x-3"},[_c('a-radio-group',{staticClass:"tw-mb-3",attrs:{"default-value":"admin","button-style":"outline"},on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.userType),callback:function ($$v) {_vm.userType=$$v},expression:"userType"}},[(_vm.$whois.admin())?_c('a-radio-button',{attrs:{"value":"admin"}},[_vm._v(" "+_vm._s(_vm.$t('user.officeStaff'))+" ")]):_vm._e(),_c('a-radio-button',{attrs:{"value":"agency"}},[_vm._v(" "+_vm._s(_vm.$t('user.agency'))+" ")]),_c('a-radio-button',{attrs:{"value":"shop"}},[_vm._v(" "+_vm._s(_vm.$t('user.shop'))+" ")]),_c('a-radio-button',{attrs:{"value":"driver"}},[_vm._v(" "+_vm._s(_vm.$t('user.driver'))+" ")])],1),_c('a-input-search',{staticStyle:{"width":"200px"},attrs:{"placeholder":_vm.$t('user.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]),_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var record = ref.record;
return [_c('td',[_vm._v(_vm._s(record.user_name))]),_c('td',[_vm._v(_vm._s(_vm.displayName(record)))]),_c('td',_vm._l((record.roles),function(role,index){return _c('span',{key:index,staticClass:"badge bg-primary mr-2 tw-capitalize"},[_vm._v(_vm._s(role.name == '*' ? 'Everything' : role.name))])}),0),_c('td',{staticClass:"tw-w-32 tw-text-center"},[_c('a-switch',{attrs:{"defaultChecked":!record.disable,"size":"small","loading":record.disable_loading},on:{"change":function($event){return _vm.onActiveChange(record)}}})],1),_c('td',{staticClass:"tw-text-center tw-space-x-3 tw-flex tw-justify-center"},[_c('a',{staticClass:"text-info",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showModal(record)}}},[_c('i',{staticClass:"fa fa-lock"})]),_c('a',{staticClass:"text-primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onEdit(record)}}},[_c('i',{staticClass:"fa fa-edit"})])])]}}])}),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1),_c('a-modal',{attrs:{"title":_vm.$t('user.resetPassword'),"width":"20%","okText":_vm.$t('user.reset'),"confirmLoading":_vm.waiting,"cancelText":_vm.$t('cancel'),"maskClosable":false},on:{"ok":_vm.handleOk,"cancel":_vm.closeModal},model:{value:(_vm.visible_resetpwd),callback:function ($$v) {_vm.visible_resetpwd=$$v},expression:"visible_resetpwd"}},[_c('div',{staticClass:"row tw-mb-3"},[_c('div',{staticClass:"col-md-12 tw-space-y-2"},[_c('label',{staticClass:"required ts-text-xs"},[_vm._v(_vm._s(_vm.$t('user.password')))]),_c('a-input-group',{attrs:{"compact":""}},[((_vm.input_type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.new_password),expression:"model.new_password"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('new_password')
                            },staticStyle:{"width":"calc(100% - 41px)"},attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model.new_password)?_vm._i(_vm.model.new_password,null)>-1:(_vm.model.new_password)},on:{"change":function($event){var $$a=_vm.model.new_password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.model, "new_password", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.model, "new_password", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.model, "new_password", $$c)}}}}):((_vm.input_type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.new_password),expression:"model.new_password"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('new_password')
                            },staticStyle:{"width":"calc(100% - 41px)"},attrs:{"type":"radio"},domProps:{"checked":_vm._q(_vm.model.new_password,null)},on:{"change":function($event){return _vm.$set(_vm.model, "new_password", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.new_password),expression:"model.new_password"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('new_password')
                            },staticStyle:{"width":"calc(100% - 41px)"},attrs:{"type":_vm.input_type},domProps:{"value":(_vm.model.new_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "new_password", $event.target.value)}}}),_c('ts-button',{attrs:{"color":"primary","outline":"","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.showPassword.apply(null, arguments)}}},[(_vm.input_type === 'text')?_c('i',{staticClass:"toggle-password fas fa-eye"}):_c('i',{staticClass:"toggle-password fa fa-fw fa-eye-slash"})])],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-space-y-2"},[_c('label',{staticClass:"required ts-text-xs"},[_vm._v(_vm._s(_vm.$t('user.confirm')))]),_c('a-input-group',{attrs:{"compact":""}},[((_vm.input_type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.confirm_password),expression:"model.confirm_password"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('confirm_password')
                            },staticStyle:{"width":"calc(100% - 41px)"},attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model.confirm_password)?_vm._i(_vm.model.confirm_password,null)>-1:(_vm.model.confirm_password)},on:{"change":function($event){var $$a=_vm.model.confirm_password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.model, "confirm_password", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.model, "confirm_password", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.model, "confirm_password", $$c)}}}}):((_vm.input_type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.confirm_password),expression:"model.confirm_password"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('confirm_password')
                            },staticStyle:{"width":"calc(100% - 41px)"},attrs:{"type":"radio"},domProps:{"checked":_vm._q(_vm.model.confirm_password,null)},on:{"change":function($event){return _vm.$set(_vm.model, "confirm_password", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.confirm_password),expression:"model.confirm_password"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('confirm_password')
                            },staticStyle:{"width":"calc(100% - 41px)"},attrs:{"type":_vm.input_type},domProps:{"value":(_vm.model.confirm_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "confirm_password", $event.target.value)}}}),_c('ts-button',{attrs:{"color":"primary","outline":"","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.showPassword.apply(null, arguments)}}},[(_vm.input_type === 'text')?_c('i',{staticClass:"toggle-password fas fa-eye"}):_c('i',{staticClass:"toggle-password fa fa-fw fa-eye-slash"})])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }