<template>
    <div>
        <ts-page-title
            :title="$t('user.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('user.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <a-button
                        type="primary"
                        @click="$router.push({ name: 'user-create' })"
                    >
                        {{ $t('addNew') }}
                    </a-button>
                    <div class="tw-space-x-3">
                        <a-radio-group
                            v-model="userType"
                            default-value="admin"
                            button-style="outline"
                            class="tw-mb-3"
                            @change="fetchData()"
                        >
                            <a-radio-button value="admin"
															v-if="$whois.admin()"
														>
                                {{ $t('user.officeStaff') }}
                            </a-radio-button>
                            <a-radio-button value="agency">
                                {{ $t('user.agency') }}
                            </a-radio-button>
                            <a-radio-button value="shop">
                                {{ $t('user.shop') }}
                            </a-radio-button>
                            <a-radio-button value="driver">
                                {{ $t('user.driver') }}
                            </a-radio-button>
                        </a-radio-group>
                        <a-input-search
                            v-model="search"
                            :placeholder="$t('user.search')"
                            style="width: 200px"
                        />
                    </div>
                </div>
            </ts-panel-wrapper>
            <ts-table
                :columns="columns"
                :records="resources"
                :loading="loading"
            >
                <template v-slot="{ record }">
                    <td>{{ record.user_name }}</td>
                    <td>{{ displayName(record) }}</td>
                    <td>
                        <span
                            class="badge bg-primary mr-2 tw-capitalize"
                            v-for="(role, index) in record.roles"
                            :key="index"
                            >{{
                                role.name == '*' ? 'Everything' : role.name
                            }}</span
                        >
                    </td>
                    <td class="tw-w-32 tw-text-center">
                        <a-switch
                            :defaultChecked="!record.disable"
                            size="small"
                            :loading="record.disable_loading"
                            @change="onActiveChange(record)"
                        />
                        <!-- <span class="badge bg-success" v-if="!record.disable"
                            >Yes</span
                        >
                        <span class="badge bg-danger" v-else>No</span> -->
                    </td>
                    <td
                        class="tw-text-center tw-space-x-3 tw-flex tw-justify-center"
                    >
                        <a
                            href="#"
                            class="text-info"
                            @click.prevent="showModal(record)"
                        >
                            <i class="fa fa-lock"></i>
                        </a>
                        <a
                            href="#"
                            class="text-primary"
                            @click.prevent="onEdit(record)"
                        >
                            <i class="fa fa-edit"></i>
                        </a>
                    </td>
                </template>
            </ts-table>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>

        <!-- reset password -->
        <a-modal
            v-model="visible_resetpwd"
            :title="$t('user.resetPassword')"
            @ok="handleOk"
            @cancel="closeModal"
            width="20%"
            :okText="$t('user.reset')"
            :confirmLoading="waiting"
            :cancelText="$t('cancel')"
            :maskClosable="false"
        >
            <div class="row tw-mb-3">
                <div class="col-md-12 tw-space-y-2">
                    <label class="required ts-text-xs">{{
                        $t('user.password')
                    }}</label>
                    <a-input-group compact>
                        <input
                            class="form-control"
                            :type="input_type"
                            v-model="model.new_password"
                            style="width: calc(100% - 41px)"
                            :class="{
                                'is-invalid': errors.has('new_password')
                            }"
                        />
                        <ts-button
                            color="primary"
                            outline
                            @click.prevent="showPassword"
                            :disabled="loading"
                        >
                            <i
                                v-if="input_type === 'text'"
                                class="toggle-password fas fa-eye"
                            ></i>
                            <i
                                v-else
                                class="toggle-password fa fa-fw fa-eye-slash"
                            ></i
                        ></ts-button>
                    </a-input-group>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-2">
                    <label class="required ts-text-xs">{{
                        $t('user.confirm')
                    }}</label>
                    <a-input-group compact>
                        <input
                            class="form-control"
                            :type="input_type"
                            v-model="model.confirm_password"
                            style="width: calc(100% - 41px)"
                            :class="{
                                'is-invalid': errors.has('confirm_password')
                            }"
                        />
                        <ts-button
                            color="primary"
                            outline
                            @click.prevent="showPassword"
                            :disabled="loading"
                        >
                            <i
                                v-if="input_type === 'text'"
                                class="toggle-password fas fa-eye"
                            ></i>
                            <i
                                v-else
                                class="toggle-password fa fa-fw fa-eye-slash"
                            ></i
                        ></ts-button>
                    </a-input-group>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import { Errors } from 'form-backend-validation'
export default {
    name: 'userIndex',
    data () {
        return {
            loading: false,
            visible_resetpwd: false,
            waiting: false,
            errors: new Errors(),
            input_type: 'password',
            input_type_confirmation: 'password',
            model: {
                user_id: 0,
                new_password: null,
                confirm_password: null
            }
        }
    },
    computed: {
        ...mapState('auth/user', ['resources', 'pagination']),
        displayName: vm => record => {
            if (!record) return ''
            if (vm.userType === 'admin') {
                return 'N/A'
            }
            if (vm.userType === 'shop') {
                return record.shop_profile ? record.shop_profile.shop_name : ''
            }
            if (vm.userType === 'driver') {
                return record.driver_profile
                    ? record.driver_profile.driver_name
                    : ''
            }
        },
        search: {
            get () {
                return this.$store.state.auth.user.search
            },
            set (newValue) {
                this.$store.commit('auth/user/SET_SEARCH', newValue)
                this.$store.commit('auth/user/RESET_CURRENT_PAGE')
            }
        },
        userType: {
            get () {
                return this.$store.state.auth.user.userType
            },
            set (newValue) {
                this.$store.commit('auth/user/SET_USER_TYPE', newValue)
            }
        },
        columns () {
            return [
                { name: this.$t('user.userName') },
                { name: this.$t('user.name') },
                { name: this.$t('user.roleName') },
                { name: this.$t('user.active'), style: 'text-align:center' },
                {
                    name: this.$t('actions'),
                    style: 'width:7%; text-align:center'
                }
            ]
        }
    },
    methods: {
        showPassword () {
            if (this.input_type === 'password') {
                this.input_type = 'text'
            } else {
                this.input_type = 'password'
            }
        },
        showPasswordConfirmation () {
            if (this.input_type_confirmation === 'password') {
                this.input_type_confirmation = 'text'
            } else {
                this.input_type_confirmation = 'password'
            }
        },
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('auth/user/fetch', attributes)
                .catch(error => {
                    this.loading = false
                    this.$toasted.error(error.message)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onEdit (record) {
            this.$store.commit('auth/user/SET_EDIT_DATA', record)
            this.$router.push({
                name: 'user-edit',
                params: { id: record.user_id }
            })
        },
        showModal (record) {
            this.model.user_id = record.user_id
            this.visible_resetpwd = true
        },
        handleOk () {
            this.waiting = true
            this.$store
                .dispatch('auth/user/resetPassword', this.model)
                .then(response => {
                    this.$notify({ type: 'success', text: response.message })
                    this.closeModal()
                })
                .catch(error => {
                    this.$notify({ type: 'error', text: error.message })
                    this.errors = new Errors(error.errors)
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        closeModal () {
            this.model.user_id = 0
            this.model.new_password = null
            this.model.confirm_password = null
            this.visible_resetpwd = false
            this.errors = new Errors()
        },
        onActiveChange (row) {
            row.disable_loading = true
            this.$store
                .dispatch('auth/user/changeStatus', {
                    id: row.user_id,
                    data: {
                        status: row.disable
                    }
                })
                .then(() => {
                    row.disable_loading = false
                    row.disable = !row.disable
                })
                .catch(() => {
                    row.disable = !row.disable
                })
                .finally(() => {
                    row.disable_loading = false
                })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    },
    beforeRouteEnter (to, from, next) {
			next(vm => {
				if (vm.$whois.agency()) {
					vm.$store.commit('auth/user/SET_USER_TYPE', 'agency')
				}
        vm.fetchData()
      })
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('auth/user/RESET_STATE')
        next()
    }
}
</script>
